<template>
  <form class="create-pass-form" @submit.prevent="generateData">
    <h3 class="create-pass-form__title">Create a new password</h3>

    <p class="create-pass-form__text">
      Risus dui duis quis tempor massa varius lectus. Molestie sollicitudin
      vitae erat sem
    </p>

    <AppField
      type="password"
      label="New password"
      required
      :isInvalid="$v.password.$error"
      @input="setField($event, 'password')"
    >
      <template v-slot:error>
        <p v-if="!$v.password.required">This field is required</p>
        <p v-if="!$v.password.minLength">
          Password must have at least
          {{ $v.password.$params.minLength.min }} symbols.
        </p>
      </template>
    </AppField>

    <div class="create-pass-btns">
      <AppButton
        title="Save"
        class="create-pass-btns__item"
        className="primary"
      />

      <AppButton
        title="Cancel"
        class="create-pass-btns__item"
        className="secondary"
        @click.prevent="cancel"
      />
    </div>
  </form>
</template>

<script>
import { minLength, required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "CreateUserPasswordModal",

  data() {
    return {
      password: "",
    };
  },

  validations: {
    password: {
      required,
      minLength: minLength(8),
    },
  },

  methods: {
    setField(fieldData, fieldName) {
      this[fieldName] = fieldData;
    },

    async generateData() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const userInfo = {
        password: this.password,
      };

      this.$emit("submit", userInfo);
    },

    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.create-pass-form {
  &__title {
    text-align: center;
    margin-bottom: 16px;
  }

  &__text {
    margin-bottom: 40px;
    text-align: center;
    color: $light-gray;
    font-size: 14px;
    line-height: 140%;
  }
}

.create-pass-btns {
  display: flex;
  align-items: center;
  width: 100%;

  &__item {
    min-width: initial;
    margin-right: 20px;
    flex: 1;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
