export const accountMixin = {
  data() {
    return {
      isEditingBlock: false,
      formKey: Date.now().toString(),
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    isActiveBlock: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setField(fieldData, fieldName) {
      this.userData[fieldName] = fieldData;

      this.isEditingBlock = true;
      this.$emit("editingBlock", this.id);
    },

    cancelChanges() {
      this.formKey = Date.now().toString();
      this.$v.$reset();
      this.$emit("cancel");
      this.isEditingBlock = false;
    },

    toggleAccountBlock() {
      this.isOpenAccountBlock = !this.isOpenAccountBlock;
    },
  },
};
