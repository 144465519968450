<template>
  <div
    class="account-item"
    :class="{ 'account-item--opened': isOpenAccountBlock }"
  >
    <div class="account-item-header">
      <h2
        class="account-item-header__title"
        @click.prevent="toggleAccountBlock"
      >
        Security

        <button class="toggle-account-btn">
          <AppIcon componentName="ChevronIcon" />
        </button>
      </h2>
      <p class="account-item-header__subtitle">
        If you’d like to change your password, enter your old password and
        nominate a new one.
      </p>
    </div>

    <form
      v-if="passwordExist"
      class="account-form account-form--security"
      :key="formKey"
      @submit.prevent="generateData"
    >
      <AppField
        required
        type="password"
        class="account-form__field"
        label="Old password"
        :isInvalid="$v.userData.oldPassword.$error"
        @input="setField($event, 'oldPassword')"
      >
        <template v-slot:error>
          <p v-if="!$v.userData.oldPassword.required">This field is required</p>
          <p v-if="!$v.userData.oldPassword.minLength">
            Password must have at least
            {{ $v.userData.oldPassword.$params.minLength.min }} symbols.
          </p>
        </template>
      </AppField>

      <div class="new-pass">
        <p class="new-pass__text">
          Your new password must be at least 8 characters long and is a
          combination of letters, numbers, and punctuation marks. Do not use
          your previous passwords.
        </p>

        <AppField
          type="password"
          label="New password"
          required
          class="account-form__field"
          :isInvalid="$v.userData.password.$error"
          @input="setField($event, 'password')"
        >
          <template v-slot:error>
            <p v-if="!$v.userData.password.required">This field is required</p>
            <p v-if="!$v.userData.password.minLength">
              Password must have at least
              {{ $v.userData.password.$params.minLength.min }} symbols.
            </p>
          </template>
        </AppField>
      </div>

      <div class="form-btns" :class="{ 'form-btns--visible': isEditingBlock }">
        <AppButton
          title="Save"
          class="form-btns__item"
          className="primary"
          type="submit"
        />
        <AppButton
          title="Cancel"
          class="form-btns__item"
          className="secondary"
          type="button"
          @click.prevent="cancelChanges"
        />
      </div>
    </form>

    <div v-else class="account-subscription without-pass">
      <AppButton
        title="Create your password"
        class="without-pass__btn"
        className="secondary"
        type="button"
        @click.prevent="openModal"
      />
    </div>

    <transition name="fade">
      <div v-if="isActiveBlock" class="disable-mask" />
    </transition>

    <AppModal :isOpenModal="isOpenModal" width="460px" @close="closeModal">
      <CreateUserPasswordModal
        @cancel="closeModal"
        @submit="createUserPassword"
      />
    </AppModal>
  </div>
</template>

<script>
import { minLength, required, sameAs } from "vuelidate/lib/validators";
import CreateUserPasswordModal from "@/components/account/CreateUserPasswordModal";
import { accountMixin } from "@/mixins/AccountMixin";

export default {
  name: "AccountFormSecurity",
  mixins: [accountMixin],
  components: { CreateUserPasswordModal },

  props: {
    passwordExist: {
      type: Boolean,
    },
  },

  data() {
    return {
      userData: {
        oldPassword: "",
        password: "",
      },
      isEditingBlock: false,
      isOpenModal: false,
      isOpenAccountBlock: false,
    };
  },

  validations: {
    userData: {
      oldPassword: {
        required,
        minLength: minLength(8),
      },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },

  methods: {
    async generateData() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const userInfo = {
        old_password: this.userData.oldPassword,
        password: this.userData.password,
      };

      this.sendUserData(userInfo);
    },

    createUserPassword(userInfo) {
      this.closeModal();
      this.sendUserData(userInfo);
    },

    sendUserData(userInfo) {
      this.$emit("submit", userInfo);
      this.isEditingBlock = false;
      this.cancelChanges();
    },

    openModal() {
      this.isOpenModal = true;
    },

    closeModal() {
      this.isOpenModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.new-pass {
  margin-top: 35px;

  &__text {
    font-size: 12px;
    color: $light-gray;
    margin-bottom: 32px;
  }
}

.without-pass {
  margin-top: 36px;
  width: 100%;

  &__btn {
    width: 100%;
  }
}
</style>
