<template>
  <div
    class="account-item"
    :class="{ 'account-item--opened': isOpenAccountBlock }"
  >
    <div class="account-item-header">
      <h2
        class="account-item-header__title"
        @click.prevent="toggleAccountBlock"
      >
        Main information

        <button class="toggle-account-btn">
          <AppIcon componentName="ChevronIcon" />
        </button>
      </h2>
    </div>

    <form
      class="account-form account-form--info"
      @submit.prevent="sendData"
      :key="formKey"
    >
      <div class="account-ava">
        <p class="account-ava__title">Photo</p>
        <figure class="account-ava__img">
          <img
            :src="user.photo || require('@/assets/images/no-ava.svg')"
            alt=""
          />

          <div class="ava-placeholder" @click.prevent="openUploadAvatarModal">
            <AppIcon componentName="UploadIcon" class="dropzone-inner__icon" />
          </div>
        </figure>

        <div class="account-ava-btns">
          <button
            class="account-ava-btns__item"
            :class="{ 'account-ava-btns__item--disabled': !user.photo }"
            :disabled="!user.photo"
            type="button"
            @click.prevent="removeUserPhoto"
          >
            Delete
          </button>
          <button
            class="account-ava-btns__item"
            type="button"
            @click.prevent="openUploadAvatarModal"
          >
            Upload new
          </button>
        </div>
      </div>

      <AppField
        class="account-form__field"
        label="First name"
        required
        :value="user.first_name"
        :isInvalid="$v.userData.firstName.$error"
        @input="setField($event, 'firstName')"
      >
        <template v-slot:error>
          <p v-if="!$v.userData.firstName.required">This field is required</p>
        </template>
      </AppField>

      <AppField
        class="account-form__field"
        label="Last name"
        required
        :value="user.last_name"
        :isInvalid="$v.userData.lastName.$error"
        @input="setField($event, 'lastName')"
      >
        <template v-slot:error>
          <p v-if="!$v.userData.lastName.required">This field is required</p>
        </template>
      </AppField>

      <AppField
        class="account-form__field"
        label="Phone number"
        type="tel"
        :value="user.phone"
        @input="setField($event, 'phone')"
      >
      </AppField>
      <AppField
        class="account-form__field"
        label="Enter your e-mail"
        type="email"
        required
        :isInvalid="$v.userData.email.$error"
        :value="user.email"
        @input="setField($event, 'email')"
      >
        <template v-slot:error>
          <p v-if="!$v.userData.email.required">This field is required</p>
          <p v-if="!$v.userData.email.email">Incorrect email format</p>
        </template>
      </AppField>

      <div class="form-btns" :class="{ 'form-btns--visible': isEditingBlock }">
        <AppButton
          title="Save"
          class="form-btns__item"
          className="primary"
          type="submit"
        />
        <AppButton
          title="Cancel"
          class="form-btns__item"
          className="secondary"
          type="button"
          @click.prevent="cancelChanges"
        />
      </div>
    </form>

    <AppModal
      :isOpenModal="isOpenModal"
      width="600px"
      @close="closeModal"
      class="upload-photo-modal"
    >
      <UploadPhotoModal
        @cancel="closeModal"
        @confirmUploadedPhoto="confirmUserPhoto"
      />
    </AppModal>

    <transition name="fade">
      <div v-if="isActiveBlock" class="disable-mask" />
    </transition>
  </div>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";
import { accountMixin } from "@/mixins/AccountMixin";

import UploadPhotoModal from "@/components/UploadUserPhotoModal";

export default {
  name: "AccountFormInfo",
  mixins: [accountMixin],

  components: { UploadPhotoModal },

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      userData: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
      },
      isOpenAccountBlock: true,
      isOpenModal: false,
    };
  },

  validations: {
    userData: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },

  methods: {
    async sendData() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const userInfo = {
        first_name: this.userData.firstName,
        last_name: this.userData.lastName,
        phone: this.userData.phone,
        email: this.userData.email,
      };
      this.$emit("submit", userInfo);
      this.isEditingBlock = false;
    },

    async removeUserPhoto() {
      await this.$store.dispatch("loader/pending");

      try {
        await this.$store.dispatch("user/removeUserPhoto");
      } catch (e) {
        console.error(e);
      }

      await this.$store.dispatch("loader/done");
    },

    async confirmUserPhoto(userPhoto) {
      await this.$store.dispatch("loader/pending");
      try {
        await this.$store.dispatch("user/uploadUserPhoto", userPhoto);
        this.closeModal();
      } catch (e) {
        console.error(e);
      }
      await this.$store.dispatch("loader/done");
    },

    openUploadAvatarModal() {
      this.isOpenModal = true;
    },

    closeModal() {
      this.isOpenModal = false;
    },
  },

  created() {
    this.userData.firstName = this.user.first_name;
    this.userData.lastName = this.user.last_name;
    this.userData.phone = this.user.phone;
    this.userData.email = this.user.email;
  },
};
</script>

<style lang="scss" scoped>
.account-ava {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 40px;

  &__title {
    font-weight: 500;
    font-size: 13px;
    width: 100%;
    color: $light-gray;
    margin-bottom: 16px;
  }

  &__img {
    width: 118px;
    height: 118px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 32px;
    position: relative;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }

    &--default {
      object-fit: contain;
      object-position: center bottom;
    }

    .ava-placeholder {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#fff, 0.9);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
      opacity: 0;
      transition: opacity 0.3s ease;

      svg {
        width: 50px;
        height: 50px;
        fill: $primary;
      }
    }

    &:hover {
      .ava-placeholder {
        opacity: 1;
      }
    }
  }

  &-btns {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: opacity 0.3s ease;

    &__item {
      font-size: 14px;
      line-height: 140%;
      text-decoration: underline;
      margin-bottom: 13px;
      padding: 0;
      color: $body-color;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        color: $primary;
      }

      &--disabled {
        color: $light-gray;
        user-select: none;
        cursor: default;

        &:hover {
          color: $light-gray;
        }
      }
    }
  }
}

::v-deep .upload-photo-modal {
  .modal {
    padding: 20px;

    @media (min-width: map_get($grid-breakpoints, md)) {
      padding: 48px;
    }
  }
}

.account-form--info {
  margin-top: 24px;
}
</style>
