<template>
  <section class="account-section">
    <div class="container">
      <h1 class="account-title">Account Settings</h1>

      <div v-if="user" class="row">
        <div class="col col-md-12 col-lg-6 col-xl-4 account-col">
          <AccountFormInfo
            id="AccountFormInfo"
            :user="user"
            :isActiveBlock="
              activeBlock !== 'AccountFormInfo' && !!activeBlock.length
            "
            @editingBlock="setActiveBlock"
            @cancel="clearActiveBlock"
            @submit="submitAccountForm"
          />
        </div>

        <div class="col col-md-12 col-lg-6 col-xl-4 account-col">
          <AccountFormSecurity
            id="AccountFormSecurity"
            :passwordExist="user.password_exists"
            :isActiveBlock="
              activeBlock !== 'AccountFormSecurity' && !!activeBlock.length
            "
            @editingBlock="setActiveBlock"
            @cancel="clearActiveBlock"
            @submit="submitAccountForm"
          />
        </div>

        <div class="col col-md-12 col-lg-6 col-xl-4 account-col">
          <AccountSubscription :isActiveBlock="!!activeBlock" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AccountFormInfo from "@/components/account/AccountFormInfo";
import AccountFormSecurity from "@/components/account/AccountFormSecurity";
import AccountSubscription from "@/components/account/AccountSubscription";
import { mapGetters } from "vuex";

export default {
  name: "UserAccount",
  components: { AccountFormInfo, AccountFormSecurity, AccountSubscription },

  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
  data() {
    return {
      activeBlock: "",
    };
  },

  methods: {
    setActiveBlock(blockName) {
      this.activeBlock = blockName;
    },

    clearActiveBlock() {
      this.activeBlock = "";
    },

    async submitAccountForm(userInfo) {
      await this.$store.dispatch("loader/pending");

      try {
        await this.$store.dispatch("user/updateUserInfo", userInfo);
      } catch (e) {
        console.error(e);
      }

      this.clearActiveBlock();
      await this.$store.dispatch("loader/done");
    },

    async getUserData() {
      await this.$store.dispatch("loader/pending");

      try {
        await this.$store.dispatch("user/getUser");
      } catch (e) {
        console.error(e);
      }

      await this.$store.dispatch("loader/done");
    },
  },

  created() {
    if (!this.user) {
      this.getUserData();
    }
  },
};
</script>

<style scoped lang="scss" src="./index.scss" />
