<template>
  <div
    v-if="!expiresDate"
    class="account-item"
    :class="{ 'account-item--opened': isOpenAccountBlock }"
  >
    <div class="account-item-header">
      <h2
        class="account-item-header__title"
        @click.prevent="toggleAccountBlock"
      >
        Subscription

        <button class="toggle-account-btn">
          <AppIcon componentName="ChevronIcon" />
        </button>
      </h2>
    </div>

    <div class="account-subscription empty-subscription">
      <figure class="empty-subscription__img">
        <img src="@/assets/images/account-subscription-img.svg" alt="" />
      </figure>

      <h4 class="empty-subscription__title">
        You Don't Have Any Subscription Yet
      </h4>

      <div class="empty-content">
        <p class="empty-content__text">
          Subscribe to Resume-Perfect to take advantage of its premium features:
        </p>

        <ul class="empty-content__list">
          <li>Access to a library of templates using any device</li>
          <li>Unlimited HR-approved resume templates</li>
          <li>Cover letter builder</li>
          <li>Document-sharing options (download, print, and email)</li>
        </ul>
      </div>

      <p class="btn-subtitle">
        Explore your options to find the best plan for your needs.
      </p>
      <AppButton
        title="View Plans"
        className="primary"
        tag="router-link"
        :to="ROUT_PATH.SUBSCRIBE_STEPS.SUBSCRIBE_PLANS"
      />
    </div>

    <transition name="fade">
      <div v-if="isActiveBlock" class="disable-mask" />
    </transition>
  </div>

  <div
    v-else
    class="account-item"
    :class="{ 'account-item--opened': isOpenAccountBlock }"
  >
    <div class="account-item-header">
      <h2
        class="account-item-header__title"
        @click.prevent="toggleAccountBlock"
      >
        Subscription
        <button class="toggle-account-btn">
          <AppIcon componentName="ChevronIcon" />
        </button>
      </h2>
      <p class="account-item-header__subtitle">Manage your subscription</p>
    </div>

    <div class="account-subscription available-subscription">
      <div class="active-plan">
        <figure class="active-plan__img">
          <img src="@/assets/images/active-plan.svg" alt="" />
        </figure>

        <div class="active-plan-content">
          <p class="active-plan-content__status">Active</p>

          <h3 class="active-plan-content__title">{{ activePlanTitle }}</h3>

          <div class="active-plan-content__expire">
            Expires at {{ expiresDate }}
          </div>
        </div>
      </div>

      <p class="next-payment">Next payment will be on {{ expiresDate }}</p>

      <figure class="subscription-img">
        <img src="@/assets/images/account-subscription-img.svg" alt="" />
      </figure>

      <AppButton
        title="Manage Subscription"
        className="red"
        class="cancel-subscription"
        tag="router-link"
        :to="ROUT_PATH.MANAGE_SUB"
      />

      <p class="subscription-text">
        By canceling your subscription, you will lose access to Resume-Perfect’s
        library of templates. Downloading your completed resumes and cover
        letters will also be unavailable. You may still renew your plan at any
        time.
      </p>
    </div>

    <AppModal :isOpenModal="isOpenModal" width="460px" @close="closeModal">
      <UnsubscribeModal @cancel="closeModal" @unsubscribe="unsubscribeUser" />
    </AppModal>

    <transition name="fade">
      <div v-if="isActiveBlock" class="disable-mask" />
    </transition>
  </div>
</template>

<script>
import UnsubscribeModal from "@/components/account/UnsubscribeModal";
import { mapGetters } from "vuex";
import { ROUT_PATH } from "@/helpers/rout-constants";

export default {
  name: "AccountSubscription",
  components: { UnsubscribeModal },

  data() {
    return {
      isOpenModal: false,
      isOpenAccountBlock: false,
      activePlanTitle: null,
      ROUT_PATH,
    };
  },

  props: {
    isActiveBlock: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      expiresDate: "user/expiresDate",
    }),
  },

  async created() {
    let user = await this.$store.dispatch("user/getUser");
    this.activePlanTitle = user.payment_subscription[0]
      ? user.payment_subscription[0].title
      : "";
  },

  methods: {
    openModal() {
      this.isOpenModal = true;
    },
    closeModal() {
      this.isOpenModal = false;
    },
    toggleAccountBlock() {
      this.isOpenAccountBlock = !this.isOpenAccountBlock;
    },
    async unsubscribeUser() {
      await this.$store.dispatch("loader/pending");
      try {
        await this.$store.dispatch("subscribe/unsubscribeUser");
        this.closeModal();
      } catch (err) {
        console.error(err);
      }
      await this.$store.dispatch("loader/done");
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-subscription {
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  &__img {
    width: 200px;
    height: 200px;
    max-width: 100%;
    margin-bottom: 50px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 8px;
  }

  &__text {
    font-size: 12px;
    color: $light-gray;
    margin-bottom: 36px;
  }
}

.empty-content {
  margin-bottom: 36px;

  &__text {
    font-size: 12px;
    color: $light-gray;
    margin-bottom: 16px;
  }

  &__list {
    font-size: 12px;
    color: $light-gray;
    list-style-type: disc;
    padding: 0 24px;
    text-align: left;
  }
}

.btn-subtitle {
  font-size: 12px;
  color: $light-gray;
  margin-bottom: 16px;
}

//Not empty plan

.available-subscription {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.active-plan {
  padding: 40px 30px;
  background-color: #fafafb;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 24px;

  &__img {
    max-width: 50px;
    margin-right: 30px;
  }

  &-content {
    &__status {
      font-weight: bold;
      font-size: 12px;
      line-height: 130%;
      color: #55bb87;
      margin-bottom: 8px;
    }

    &__title {
      font-size: 16px;
      font-weight: bold;
    }

    &__expire {
      font-size: 12px;
      color: $light-gray;
    }
  }
}

.next-payment {
  font-size: 12px;
  color: $light-gray;
  text-align: center;
}

.subscription-img {
  margin: auto 0;
  padding: 20px 0;
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  img {
    width: 135px;
    height: auto;
  }
}

.cancel-subscription {
  width: 100%;
  margin-bottom: 16px;
}

.subscription-text {
  font-size: 12px;
  color: $light-gray;
  text-align: center;
}
</style>
